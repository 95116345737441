import { Injectable } from '@angular/core';
import { WebApiService } from './web-api.service';
import { User } from '../../models/User';
import { Subject, Observable } from 'rxjs';

@Injectable()

export class UsersService {
    private _mustRefreshSubject: Subject<void>;
    refreshAnnounced$: Observable<void>;

    constructor(
        private webApiService: WebApiService
    ) {
        this._mustRefreshSubject = new Subject<void>()
        this.refreshAnnounced$ = this._mustRefreshSubject.asObservable();
    }

    announceRefresh() {
        this._mustRefreshSubject.next();
    }

    getUsers(page, filters) {
        return this.webApiService.getUsers(page, filters);
    }

    getUsersInfo() {
        return this.webApiService.getUsersInfo();
    }

    toggleUserAccess(user: User) {
        user.apiAccess = !user.apiAccess;
        this.webApiService.updateUser(user).then(() => {
            this.announceRefresh();
        });
    }

    sendCredentialsEmail(userId: string) {
        return this.webApiService.sendUserCredentialsEmail(userId);
    }
}