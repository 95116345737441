import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { StorageService } from './storage.service';
import { WebApiService } from './web-api.service';

@Injectable()
export class LoginService {
    constructor(
        private httpService: AppHttpService,
        private storageService: StorageService,
        private webApiService: WebApiService) {
    }
 
    
    validateClientId(clientId) {
        return this.webApiService.validateClientId(clientId).then((result) => {
            return result;
        }).catch((error) => {
            throw error;
        });
    }

    login(login) {
        return this.webApiService.login(login).then((result) => {
            let accessToken = result['access_token'];
            let refreshToken = result['refresh_token'];
            this.httpService.updateTokens(accessToken, refreshToken, login.remember);           
        }).catch((error) => {
            throw error;
        });
    }

    logout() {
        this.httpService.clearTokens();
    }

    isLoggedIn(): boolean {
        return this.storageService.get('access_token') != null && this.storageService.get('refresh_token') != null;
    }
}
